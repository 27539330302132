export const DeleteNestedProperty = (state: any, { key }: { key: string }) => {
  const keys = key.split("."); // Assuming dot notation for nested keys
  const lastKey = keys.pop();

  if (!lastKey) return state;

  let currentLevel = state;
  for (const k of keys) {
    currentLevel = currentLevel[k];
    if (!currentLevel) return state;
  }

  if (currentLevel && lastKey in currentLevel) {
    delete currentLevel[lastKey];
  }

  return { ...state };
};
